/**
 * Hero slick slider
 */
const heroSlider = () => {
	// Hero Slider
	$( '.hero-slider' ).slick( {
		slidesToShow: 1,
		autoplay: true,
		autoplaySpeed: 5000,
		infinite: true,
		speed: 300,
		dots: true,
		arrows: true,
		fade: true
	} );
};

/**
 * Item slick slider
 */
const itemSlider = () => {
	// Item Slider
	$( '.items-container' ).slick( {
		infinite: true,
		arrows: true,
		autoplay: true,
		slidesToShow: 3,
		slidesToScroll: 1,
		responsive: [ {
				breakpoint: 991,
				settings: {
					slidesToShow: 2,
					arrows: false
				}
			},
			{
				breakpoint: 525,
				settings: {
					slidesToShow: 1,
					arrows: false
				}
			}
		]
	} );
};

/**
 * Testimonials slick slider
 */
const testimonialsSlider = () => {
	$( '.testimonial-carousel' ).slick( {
		infinite: true,
		arrows: false,
		autoplay: true,
		slidesToShow: 2,
		dots: true,
		slidesToScroll: 2,
		responsive: [ {
				breakpoint: 991,
				settings: {
					slidesToShow: 2
				}
			},
			{
				breakpoint: 525,
				settings: {
					slidesToShow: 1
				}
			}
		]
	} );
};

/**
 * Enable Smooth Scrolling
 * Based on the solution by Chris Coyier of CSS-Tricks.com
 */
var smoothScrolling = () => {
    document.querySelectorAll( 'a.smoothscroll[href*="#"]' ).forEach( function ( item, idx ) {
        item.addEventListener( 'click', (e) => {
            e.preventDefault();
            smoothScrollTo( item );
        } );
    } );
}

/**
 * Smooth scrolls from item in page
 * @param {Object} linkItem 
 */
const smoothScrollTo = ( linkItem  ) => {
    if ( location.pathname.replace( /^\//, '' ) == linkItem.pathname.replace( /^\//, '' ) && location.hostname == linkItem.hostname ) {
        let target = linkItem.hash;
        target = target.length ? target : document.querySelector( '[name=' + linkItem.hash.slice( 1 ) + ']' );
        let stickynavLength = document.querySelector( '.navbar-top.sticky-top' ).offsetHeight;
        if ( target.length ) {
            window.scroll( {
                behavior: 'smooth',
                left: 0,
                top: document.querySelector( target ).offsetTop - stickynavLength
            } );
        }
    }
};

/**
 * Execute functions after DOM load
 */
document.addEventListener( 'DOMContentLoaded', () => {
    smoothScrolling();
} );

/**
 * jQuery dependent functions 
 */
jQuery( document ).ready( function ( $ ) {

    // Page load
    $( function () {
        heroSlider();
        itemSlider();
        testimonialsSlider();
        $( 'body' ).scrollspy( {
            target: '#scroll-nav',
            offset: document.querySelector( '.navbar-top.sticky-top' ).offsetHeight
        } );
    } );

} );
